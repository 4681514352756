import React from 'react';
// import Service from 'views/Service';
import Main from 'layouts/Main';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Container from 'components/Container';

const AboutPage = () => {
  const theme = useTheme();

  return (
    <Main>
      <Box
        sx={{
          backgroundColor: theme.palette.alternate.main,
          backgroundImage: `linear-gradient(120deg, ${theme.palette.background.paper} 0%, ${theme.palette.alternate.main} 100%)`,
          position: 'relative',
          marginTop: -13,
          paddingTop: 13,
        }}
      >
        <Container position={'relative'} zIndex={3}>
          <p>
            &nbsp;&nbsp;&nbsp;การแพร่ระบาดของโรค Covid-19
            ไปทั่วทั้งโลกตลอดระยะเวลามากกว่า 1 ปี
            โลกได้รู้ซึ้งถึงมหันตภัยอันใหญ่หลวงต่อมวลมนุษยชาติ
            ต่อจากนี้มนุษย์ต้องปรับตัวอย่างขนานใหญ่เพื่อเอาตัวรอด
            ระบบอัตโนมัติถือเป็นหนทางรอดทางหนึ่ง
            เนื่องจากเชื้อโรคไม่สามารถทำให้เครื่องจักรล้มป่วยได้
            ดังนั้นถ้าวันหนึ่งมนุษย์เราสามารถสร้างเครื่องจักรที่ทำงานได้เองโดยอัตโนมัติโดยพึ่งพามนุษย์ให้น้อยที่สุด
            เราจะมีโรงงานที่ผลิตสินค้าต่างๆ เองอัตโนมัติ ผลิตอาหาร
            ผลิตยารักษาโรคเอง
            ถึงเวลานั้นมนุษย์แต่ละคนก็จะสามารถทำงานอย่างปลอดภัยอยู่ที่บ้านและสามารถก้าวข้ามภัยพิบัตินี้ไปได้
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;การอุบัติขึ้นของ Covid-19
            กลายเป็นตัวเร่งให้เทคโนโลยีการผลิตอัตโนมัติของโลกยิ่งต้องรีบพัฒนาไปสู่
            Industry 4.0 ให้เร็วยิ่งขึ้น
            เพื่อให้เป็นระบบการผลิตอัตโนมัติโดยสมบูรณ์
            ที่ไม่ใช้มนุษย์เลย(หรือใช้ให้น้อยที่สุด) เพื่อหลีกเลี่ยงปัญหา Human
            Error ในกระบวนการผลิต ช่วยเพิ่มประสิทธิภาพและประสิทธิผล
            และนำไปสู่การลดต้นทุนการผลิตในที่สุด
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;สำหรับประเทศไทยนั้นยังมีความล้าหลังในเทคโนโลยีการผลิตอย่างมาก
            โรงงานส่วนใหญ่โดยเฉพาะ SMEs ยังใช้เทคโนโลยีที่เก่าล้าสมัย
            ดังนั้นอุตสาหกรรมไทยจำเป็นต้องรีบปรับเปลี่ยนให้ทันก่อนที่ทุกอย่างจะสายเกินไป
            จึงเป็นความเร่งด่วนที่ สถาบันการศึกษา หน่วยงานวิจัยพัฒนา
            และวงการอุตสาหกรรมไทย
            จะต้องผนึกกำลังเพื่อพัฒนาเทคโนโลยีการผลิตที่ก้าวหน้าทันสมัยไปสู่
            Industry 4.0 ขึ้นมาได้เอง
            มีแพลตฟอร์มที่จะช่วยพัฒนาระบบอัตโนมัติที่โรงงานผู้ผลิตต่างๆ โดยเฉพาะ
            SMEs สามารถเข้าถึงได้
            และได้รับการถ่ายทอดเทคโนโลยีได้โดยง่ายและในราคาประหยัด
            โดยเฉพาะอย่างยิ่งซอฟต์แวร์ SCADA
            ซึ่งถือว่าเป็นซอฟต์แวร์แพลตฟอร์มที่สำคัญที่สุดของ Industry 4.0
            จึงเป็นที่มาของโครงการนี้
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;โครงการวิจัยและพัฒนาแพลตฟอร์มเปิดสเกด้าไทยสำหรับอุตสาหกรรมเอสเอ็มอีมุ่งวิจัยและพัฒนาซอฟต์แวร์แพลตฟอร์ม
            SCADA ที่เป็นระบบเปิด เพื่อให้ง่ายต่อการพัฒนาต่อยอด ขยายผล
            และนำไปติดตั้งใช้งานอย่างแพร่หลายในภาคอุตสาหกรรมโดยเฉพาะกลุ่ม SMEs
            และเป็นประโยชน์กับชุมชนนักพัฒนาซอฟต์แวร์สำหรับ Industry 4.0 ของไทย
            ซอฟต์แวร์แพลตฟอร์มที่วิจัยและพัฒนาขึ้นมานี้มีชื่อว่า “Thai Open
            SCADA” โดยพัฒนาต่อยอดมาจาก Web SCADA ซึ่งเป็นซอฟต์แวร์ต้นแบบ SCADA
            ขั้นพื้นฐาน
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;โครงการนี้ได้มีการวิจัยพัฒนาซอฟต์แวร์แพลตฟอร์ม
            SCADA สำหรับ Industry 4.0
            ขึ้นโดยเริ่มจากศึกษาเปรียบเทียบจากผลิตภัณฑ์ของต่างประเทศ การรวบรวม
            Requirement จากบริษัท Consortium ผู้ร่วมให้ทุนวิจัย
            การออกแบบซอฟต์แวร์ SCADA ขึ้นมาใหม่ โดยอาศัยพื้นฐานการพัฒนา Web
            SCADA โดยอาศัยหลักการวิเคราะห์ออกแบบเชิง Object-Oriented ทั้งหมด
            และพัฒนาด้วยแพลตฟอร์มเว็บ โดยที่ฝั่ง Server ใช้ Django Web Framework
            ที่เป็นภาษา Python ในการพัฒนา และฝั่ง Web Client ใช้ React Framework
            ที่เป็นภาษา JavaScript ร่วมกับ HTML โดยทั้งหมดเป็นซอฟต์แวร์ Open
            Source ทั้งสิ้น
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;การพัฒนาซอฟต์แวร์ฝั่ง Server
            ประกอบด้วยการพัฒนาส่วน (1) SCADA ที่เป็นแกนหลักของแพลตฟอร์ม
            ทำหน้าที่บริหารจัดการ
            เฝ้าติดตามตรวจสอบและควบคุมการทำงานของเครื่องจักรโดยการสื่อสารกับเครื่องจักรผ่านส่วน
            (2) IoT Interface ซึ่งเป็นส่วน Worker
            ที่เชื่อมต่อรับส่งข้อมูลโดยอาศัยโปรโทคอลการสื่อสารผ่านทางสายสื่อสารหรือการสื่อสารไร้สายตลอดเวลา
            ระบบได้รับการออกแบบมาให้สามารถขยายการรองรับโปรโทคอลด้านอุตสาหกรรมอัตโนมัติ
            อันได้แก่ ModBus, CAN, EtherCAT, OPC-UA ฯลฯ และ ด้าน IoT (Internet
            of Things) ได้แก่ LoRA, MQTT ฯลฯ ได้เพิ่มขึ้นเรื่อยๆ โดยไม่จำกัด
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;นอกจากนี้ยังได้เพิ่มขีดความสามารถด้านปัญญาประดิษฐ์ให้กับ
            SCADA (Core) โดยพัฒนา (3) Agent ไว้คอยช่วย SCADA
            คิดตัดสินใจโดยอาศัยกฎความรู้ ในการแจ้งเตือน ในการทำงานอัตโนมัติต่างๆ
            และการคิดวิเคราะห์แบบ (4) Real-time Agent Communication อีกทั้ง
            SCADA
            ที่พัฒนาขึ้นยังสามารถจัดเก็บและประมวลผลข้อมูลเชิงเรขาคณิตและเชิงพื้นที่ผ่านความสามารถทางด้าน
            (5) ระบบภูมิสารสนเทศ (GIS) อีกด้วย
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;สำหรับการพัฒนาซอฟต์แวร์ฝั่ง Client
            เป็นการพัฒนาส่วนแสดงผล User Interface และ การแสดงผล Widget ทั้งแบบ 2
            มิติและ 3 มิติ ซึ่งประการหลังอาศัยส่วนแสดงผล 3 มิติ ด้วย (7) VR
            (Virtual Reality)
            เมนูการใช้งานโปรแกรมสามารถรองรับการเปลี่ยนภาษาเป็นภาษาต่างประเทศได้หลากหลายไม่จำกัด
            การปรับเปลี่นเฉดสีของหน้าจอเป็นโทนสีตามที่ผู้ใช้ชื่นชอบ
            ทั้งหมดนี้ช่วยสร้างความแตกต่างให้กับ Thai Open SCADA
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;ด้วยองค์ประกอบความสามารถมากมายหลายประการของแพลตฟอร์ม
            Thai Open SCADA
            ซึ่งได้รับการออกแบบพัฒนาให้เข้ากันได้อย่างลงตัวทั้งหมด
            และยังสามารถพัฒนาต่อขยายเปิดกว้างได้ในทุกๆส่วน โดยอาศัยซอฟต์แวร์
            Open Source ทั้งนี้เพื่อให้เป็นแพลตฟอร์มระบบเปิดของซอฟต์แวร์ SCADA
            สำหรับกลุ่มบริษัท SME ของไทยที่จะมุ่งพัฒนาไปสู่ Industry 4.0
            และที่สำคัญซอฟต์แวร์แพลตฟอร์มนี้ ยังสามาถติดตั้งใช้งานบนเครื่อง
            Raspberry Pi 4 ขนาด RAM 8 GB และ storage ขนาด 32 GB ราคาใม่เกิน
            3,000 บาท ได้เป็นอย่างดี
            ซึ่งผลงานการวิจัยและพัฒนานนี้มีศักยภาพที่จะกลายเป็นเครื่องจักรอันสำคัญที่จะนำไปใช้ในการแข่งขันและขับเคลื่อนให้เกิดการเติบโตของอุตสาหกรรม
            SMEs ไทยในโลกยุคหลัง Covid-16 ต่อจากนี้ไป
          </p>
        </Container>
      </Box>
    </Main>
  );
};

export default AboutPage;
